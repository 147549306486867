/**
 * Inhaltsverzeichnis
 * 	1. Own Axis
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #═#═#═#═#═# 0.1 Imports #═#═#═#═#═#


// #═#═#═#═#═# 0.2 Types #═#═#═#═#═#
interface Parameter	{
	trackSpan?: number
};


// ══════════════════════════════════════════════════
// MARK: 1. Own Axis
// ──────────────────────────────────────────────────
export default class OwnAxis {
	// #═#═#═#═#═# 1.1 Properties #═#═#═#═#═#
		// #════ Static ════#
	/** Check Value für CombinedLayout Class for contained setter methods */
	static readonly publicAccessors = [
		"trackSpan"
	];


		// #════ Uninitialized ════#


		// #════ Initialized ════#
	/** Amount of tracks to span the current element */
	private __trackSpan: number	=	1;


	// #═#═#═#═#═# 1.2 Constructor #═#═#═#═#═#
	constructor(params?: Parameter) {
		// #════ Properties ════#
		if(params?.trackSpan)
			this.trackSpan	=	params.trackSpan;


		// #════ Actions ════#


	}


	//
	// MARK: Accessors
	//
	// #═#═#═#═#═# 1.? Track Span #═#═#═#═#═#
	/**
	 * Get the amount of tracks to span the current element
	 */
	public get trackSpan(): number {
		return this.__trackSpan;
	}

	/**
	 * Set the amount of tracks to span the current element
	 */
	public set trackSpan(value: number) {
		this.__trackSpan	=	Math.max(value, 0);
	}
}