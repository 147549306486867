/**
 * Inhaltsverzeichnis
 * 	1. Base Element
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #═#═#═#═#═# 0.1 Imports #═#═#═#═#═#
import { CSSRuleSet, CSSStyleSheetManager } from '../../ts/class/style/exportlist.ts';
import { debounce } from '../../ts/module/timing.ts';
import GridLayout from '../grid/assets/grid-layout/';
import CombinedLayout from '../grid/assets/combined-layout/';
import OwnLayout from '../grid/assets/own-layout/';


// ══════════════════════════════════════════════════
// MARK: 1. Base Element
// ──────────────────────────────────────────────────
export default abstract class BaseElement extends HTMLElement {
	// #═#═#═#═#═# 1.1 Properties #═#═#═#═#═#
		// #════ Static ════#
	/** An Interface for all contained styles staticaly */
	static readonly _styles:CSSRuleSet;


		// #════ Initialised ════#
	/** ShadowRoot of the Element */
	public shadowRoot:ShadowRoot	=	this.attachShadow({
		mode: 'open',
		slotAssignment: 'manual'
	});
	/** An Interface for all contained styles localy */
	readonly _styles:CSSRuleSet					=	new CSSRuleSet();
	/**	Object containing the Ruleset for the Host Element */
	protected _ruleset:CSSStyleSheetManager		=	new CSSStyleSheetManager({
		static: Object.getPrototypeOf(this).constructor._styles,
		element: this._styles
	});
	/** Child Element Observer */
	private _nodeObserver:MutationObserver		=	new MutationObserver(this.assignSlots.bind(this));
	/**	Debounced Update Function */
	protected readonly requestUpdate:Function	=	debounce(this.update.bind(this), 100);


		// #════ Uninitialized ════#
	/** An object for mapping Attribute names to property variables */
	protected static readonly attributeMap?:Record<string, string>;
	/** Contained Slot */
	private _slot!: HTMLSlotElement;
	/** The layouting helper class instance that can be passed to the webworker */
	protected readonly layout!:GridLayout|CombinedLayout|OwnLayout;


	// #═#═#═#═#═# 1.2 Constructor #═#═#═#═#═#
	constructor(
		
	) {
		// #════ Parent ════#
		super();


		// #════ Properties ════#
		this.shadowRoot.adoptedStyleSheets		=	this._ruleset.list();


		// #════ Actions ════#
		this.build();		
		this._nodeObserver.observe(this, {
			childList: true
		});
	}


	//
	// MARK: Shadow Root
	//
	// #═#═#═#═#═# 1.3 Shadow Root HTML #═#═#═#═#═#
	protected build(
	):void {
		this._slot	=	document.createElement('slot');
		this.shadowRoot.appendChild(this._slot);
	}


	//
	// MARK: Methods
	//
	// #═#═#═#═#═# 1.? Get Attribute Map #═#═#═#═#═#
	/**
	 * Return the current attribute map
	 */
	protected get attributeMap(
	):Record<string, string>|undefined {
		return Object.getPrototypeOf(this).constructor.attributeMap;
	}


	//
	// MARK: Lifecycle
	//
	// #═#═#═#═#═# 1.? Connected Callback #═#═#═#═#═#
	/**
	 * Connected Callback
	 */
	public connectedCallback(
	):void {
		this.assignSlots();
	}


	// #═#═#═#═#═# 1.? Update #═#═#═#═#═#
	/**
	 * Update the element
	 */
	protected update(
		...args:any[]
	): void {
		// console.groupCollapsed('Element Update');
		const css = this.layout.generateCSS(':host');

		// console.log(css);
		this._styles.replaceRules(css);
		// console.groupEnd();


		// #════ Finalize ════#
		this.afterUpdate(...args);
	}


	// #═#═#═#═#═# 1.? After Update #═#═#═#═#═#
	/**
	 * Called after the element has been updated
	 */
	protected afterUpdate(
		...args:any[]
	): void {
		// console.log('After Update');
	}


	//
	// MARK: Manual Slots
	//	
	// #═#═#═#═#═# 1.? Slot Asignment #═#═#═#═#═#
	/**
	 * Manually assign the slots.
	 */
	private assignSlots(): void {
		// #════ Prepare Storage ════#
		const nodes:Element[]			=	[];


		// #════ Collect Nodes ════#
		for(const node of this.children) {
			if(node instanceof HTMLStyleElement) {
				this.shadowRoot.appendChild(node);
			}

			else {
				nodes.push(node);
			}
		}


		// #════ Assign Slots ════#
		this._slot.assign(...nodes);
	}
}