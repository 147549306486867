/**
 * Inhaltsverzeichnis
 * 	1. Imports
 * 
 */


// ==================================================
// 1. Imports
// --------------------------------------------------



// ==================================================
// 2. Debounce
// --------------------------------------------------
/**
 * Debounces a function
 * @param		callback		The function to debounce
 * @param		wait			The time to wait in milliseconds
 * @param		immediate		Whether to execute the function immediately
 * @returns						The debounced function
 */
export function debounce(
	callback:Function,
	wait:number			= 20,
	immediate:boolean	= false
): Function {
	let timeout: number | undefined;
	return function(this: Function) {
		const	context		=	this,
				args		=	arguments,
				later		=	function() {
					timeout		=	undefined;
					if (!immediate) callback.apply(context, args);
				},
				callNow		=	immediate && !timeout;
		clearTimeout(timeout);
		timeout	=	setTimeout(later, wait);
		if (callNow) callback.apply(context, args);
	};
}