/**
 * Inhaltsverzeichnis
 * 	1. Grid Item Element
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ==================================================
// MARK: 0. Setup
// --------------------------------------------------
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import BaseElement from '../../base/index.tsx';
import { CSSRuleSet } from '../../../ts/class/style/exportlist.ts';
import stylesheet from './style.scss?inline';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#


// ==================================================
// MARK: 1. Grid Item Element
// --------------------------------------------------
export default class HTMLGridItemElement extends BaseElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
			// #==== Static ====#
	static readonly _styles:CSSRuleSet			=	new CSSRuleSet(stylesheet);
	/** List of observed Attributes */
	static readonly observedAttributes			=	[
		'column-span',
		'row-span'
	];


		// #==== Initialised ====#


		// #==== Uninitialized ====#


	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor(
		
	) {
		// #==== Parent ====#
		super();


		// #==== Properties ====#


		// #==== Actions ====#

	}


	// #=#=#=#=#=# 1.? Changed Attributes #=#=#=#=#=#
	public attributeChangedCallback(
		name:string,
		oldValue:string,
		newValue:string
	):void {
		// #==== Property change ====#
		switch(name) {
			case 'column-span':
				this._styles.setProperty(':host', 'grid-column', `span ${newValue}`);
				break;
			case 'row-span':
				this._styles.setProperty(':host', 'grid-row', `span ${newValue}`);
				break;
		}
	}
}


// ==================================================
// MARK: 2. Initialization
// --------------------------------------------------
window.customElements.define('gw-grid-item', HTMLGridItemElement);