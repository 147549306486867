"use strict";
/**
 * Inhaltsverzeichnis
 * 	1. 
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #═#═#═#═#═# 0.1 Imports #═#═#═#═#═#
import type {
	CSSUnit,
	CSSLength
} from "../types";


// #═#═#═#═#═# 0.2 Types #═#═#═#═#═#
type GapSizeVariables	=	typeof GridAxis.gapSizes[number];
interface Parameter	{
	maxLength?: number,
	trackCount?: number|'subgrid',
	gap?: number|GapSizeVariables|CSSLength
};


// ══════════════════════════════════════════════════
// MARK: 1. Grid Axis
// ──────────────────────────────────────────────────
export default class GridAxis {
	// #═#═#═#═#═# 1.1 Properties #═#═#═#═#═#
		// #════ Static ════#
	/** Possible variable Gap Sizes */
	public static readonly gapSizes	=	['xxs', 'xs', 's', 'm', 'l', 'xl', 'xxl'] as const;
	/** Check Value für CombinedLayout Class for contained setter methods */
	static readonly publicAccessors = [
		"maxLength",
		"trackCount",
		"gap"
	];


		// #════ Uninitialized ════#
	/** Max length of the axis */
	private __maxLength: number;


		// #════ Initialized ════#
	/** Determins if the axis inherits its properties from the parent element */
	private __isSubgrid: boolean	=	false;
	/** Amount of Elements within the axis */
	private __trackCount: number	=	0;
	/** Space between each track */
	private readonly __gap: {
		value: number|GapSizeVariables|CSSLength,
		length: number
	}	=	{
		value: 0,
		length: 0
	};


	// #═#═#═#═#═# 1.2 Constructor #═#═#═#═#═#
	constructor(params?: Parameter) {
		// #════ Properties ════#
		if(params?.maxLength)
			this.maxLength	=	params.maxLength;
		if(params?.trackCount)
			this.trackCount	=	params.trackCount;
		if(params?.gap)
			this.gap		=	params.gap;


		// #════ Actions ════#


	}


	//
	// MARK: Accessors
	//
	// #═#═#═#═#═# 1.? Track Count #═#═#═#═#═#
	/**
	 * Get the amount of tracks within the axis
	 */
	public get trackCount(): number {
		return this.__trackCount;
	}

	/**
	 * Set the amount of tracks within the axis
	 */
	public set trackCount(value: number|'subgrid') {
		// #════ Guard ════#
		if(value === 'subgrid') {
			this.__isSubgrid	=	true;
			this.__trackCount	=	0;
			return
		}


		// #════ Set ════#
		this.__isSubgrid	=	false;
		this.__trackCount	=	Math.max(value, 0);
	}


	// #═#═#═#═#═# 1.? Subgrid #═#═#═#═#═#
	/**
	 * return the boolean value of the subgrid property
	 */
	public get isSubgrid(): boolean {
		return this.__isSubgrid;
	}


	// #═#═#═#═#═# 1.? Track Length #═#═#═#═#═#
	/**
	 * Get the size of a single track
	 */
	public get trackWidth(): number {
		// #════ Guard ════#
		// BOOKMARK: Potential Bug with "subgrid" Track Count
		if(this.trackCount <= 0) {
			return 0;
		}


		// #════ Return ════#
		return (this.maxLength - this.gapTotalLength) / this.trackCount;
	}


	// #═#═#═#═#═# 1.? Gap Count #═#═#═#═#═#
	/**
	 * Get the amount of gaps within the axis
	 */
	public get gapCount(): number {
		// #════ Guard ════#
		// BOOKMARK: Potential Bug with "subgrid" Track Count
		if(this.trackCount <= 0) {
			return 0;
		}


		// #════ Return ════#
		return this.trackCount - 1;
	}


	// #═#═#═#═#═# 1.? Gap Size #═#═#═#═#═#
	/**
	 * Get the user defined gap size
	 */
	public get gap(): string {
		// #════ Convert ════#
		// Check if value is one of the predefined sizes
		if (GridAxis.gapSizes.includes(this.__gap.value as GapSizeVariables)) {
			return `var(--cvh-space-${this.__gap.value}, ${this.__gap.length}px)`;
		}

		// Check if number (assume pixels)
		if (typeof this.__gap.value === 'number') {
			return `${this.__gap.value}px`;
		}


		// #════ Return ════#
		return this.__gap.value as CSSLength;
	}

	/**
	 * Set the user defined gap size
	 */
	public set gap(value: number|GapSizeVariables|CSSLength) {
		this.__gap.value	=	value;
		this.__gap.length	=	GridAxis.getSpacingInPixels(value, this.maxLength);
	}


	// #═#═#═#═#═# 1.? Gap Length #═#═#═#═#═#
	/**
	 * Get the numerical length of the gap between each track
	 */
	public get gapLength(): number {
		return this.__gap.length;
	}


	// #═#═#═#═#═# 1.? Gap Total Length #═#═#═#═#═#
	/**
	 * Get the total length of all gaps within the axis
	 */
	public get gapTotalLength(): number {
		return this.gapLength * this.gapCount;
	}


	// #═#═#═#═#═# 1.? Max Length #═#═#═#═#═#
	/**
	 * Get the maximum length of the axis
	 */
	public get maxLength(): number {
		return this.__maxLength;
	}

	/**
	 * Set the maximum length of the axis
	 */
	public set maxLength(value: number) {
		this.__maxLength	=	value;
	}


	//
	// MARK: Converter
	//
	// #=#=#=#=#=# 1.? Get Spacing Value #=#=#=#=#=#
	/**
	 * Converts a size string into its pixel value
	 */
	protected static getSpacingInPixels(
		value: number|GapSizeVariables|string,
		maxLength: number
	): number {
		// #════ Guard ════#
		if(typeof value === 'number') {
			return value;
		}


		// #════ Variable Spacings ════#
		switch(value) {
			case 'xxs': 
				return 5;
			case 'xs': 
				return 15;
			case 's':
				return 25;
			case 'm':
				return 35;
			case 'l':
				return 50;
			case 'xl':
				return 75;
			case 'xxl':
				return 115;
		}


		// #════ Unit Spacings ════#
		const match:{
			length: string,
			unit: CSSUnit
		}|undefined		=	value.match(/(?<length>\d+)(?<unit>px|rem|vw|vh|dvw|dvh|svw|svh|lvw|lvh|vmin|vmax|%)/)?.groups as {length: string,unit: CSSUnit}|undefined;

			// +──── Guard ────+
			// No Match
		if (!match) {
			return 0;
		}

			// Convert to Pixels
		const length	=	parseInt(match.length);

			// No Unit = Assume Pixels
		if(!match.unit) {
			return length;
		}


		// #════ Convert to Pixels ════#
		switch(match.unit) {
			// +──── Pixel ────+
			case 'px':
				return length;
			// +──── Root Font Size ────+
			case 'rem':
				return length * 10;
			// +──── Viewport Width ────+
			case 'vw':
			case 'dvw':
			case 'svw':
			case 'lvw':
				return window.innerWidth * (length / 100);
			// +──── Viewport Height ────+
			case 'vh':
			case 'dvh':
			case 'svh':
			case 'lvh':
				return window.innerHeight * (length / 100);
			// +──── Viewport Min/Max ────+
			case 'vmin':
				return Math.min(window.innerWidth, window.innerHeight) * (length / 100);
			case 'vmax':
				return Math.max(window.innerWidth, window.innerHeight) * (length / 100);
			// +──── Parent Width ────+
			case '%':
				return maxLength * (length / 100);
		}


		// #════ Default ════#
		return 0;
	}
}


// ══════════════════════════════════════════════════
// MARK: . 
// ──────────────────────────────────────────────────
Object.defineProperty(window, 'GridAxis', {
	value: GridAxis,
	writable: false
});