/**
 * Inhaltsverzeichnis
 * 	1. 
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import BaseElement from '../../base/index.tsx';
// import WorkerPool from '../../../ts/class/workerpool/index.ts';
import { CSSRuleSet } from '../../../ts/class/style/exportlist.ts';
import GridLayout from '../assets/grid-layout';
import CombinedLayout from '../assets/combined-layout';
import stylesheet from './style.scss?inline';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#
import type {
	AxisDirection,
} from '../assets/types.d.ts';
// import type GWSubgridElement from '../subgrid/index.tsx';
import Sanitizer from '../../../ts/class/sanitizer/index.ts';


// ══════════════════════════════════════════════════
// MARK: 1. HTML Grid Element
// ──────────────────────────────────────────────────
export default class GWGridElement extends BaseElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #════ Static ════#
	/** Webworkers */
	// static readonly workers:WorkerPool		=	new WorkerPool(
	// 	() => new Worker(new URL('../worker.ts', import.meta.url), {
	// 		type: 'module'
	// 	}),
	// 	6,
	// 	false,
	// 	3000
	// );
	/** The Stylesheet for the gloabl Element */
	static readonly _styles:CSSRuleSet		=	new CSSRuleSet(stylesheet);
	/** List of observed Attributes within the an axis */
	static readonly axisAttributes			=	[
		'count',
		'gap',
		// 'auto-flow',
		// 'track-pattern',
		'axis-length',
	];
	/** Attribute to Property Map */
	protected static readonly attributeMap	=	{
		'count':		'trackCount',
		'axisLength':	'maxLength',
	};


		// #════ Initialised ════#
	/** The layouting helper class instance that can be passed to the webworker */
	protected readonly layout:GridLayout|CombinedLayout	=	new GridLayout(this.attributeMap);


		// #════ Uninitialized ════#


	//
	// MARK: Constructor
	//
	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor(
		
	) {
		// #════ Parent ════#
		super();


		// #════ Properties ════#


		// #════ Actions ════#


		// #════ Observe Resize ════#
		// HTMLGridElement.resizeObserver.observe(this);
	}


	//
	// MARK: Worker
	//
	// #═#═#═#═#═# 1.? Webworker Change #═#═#═#═#═#
	// private webworkerChange(
	// ): void {	
	// 	// #════ Add Task ════#
	// 	const task:WorkerRequest	=	{
	// 		layouter: this.layout,
	// 		elementRect: this.getBoundingClientRect(),
	// 		screen: {
	// 			width: window.innerWidth,
	// 			height: window.innerHeight
	// 		}
	// 	};
	// 	const response	=	HTMLGridElement.workers.addTask(task);

	// 	response.then((response:WorkerResponse) => {
	// 		console.groupCollapsed('Webworker Response:');
	// 		console.log(response.layouter);
	// 		console.log(response.ruleset);
	// 		console.log(new CSSRuleSet(response.ruleset));
	// 		console.groupEnd();
	// 	});
	// }


	//
	// MARK: Lifecycle
	//
	// #═#═#═#═#═# 1.? Get Observed Attributes #═#═#═#═#═#
	/**
	 * Create the list of observed attributes
	 */
	static get observedAttributes(): string[] {
		const attributes:string[]	=	[];

		for(const axis of ['column', 'row'] as AxisDirection[]) {
			for(const attribute of this.axisAttributes) {
				attributes.push(`${axis}-${attribute}`);
			}
		}

		return attributes;
	}


	// #═#═#═#═#═# 1.? Element Mount #═#═#═#═#═#
	/**
	 * Called when the element is mounted to the DOM
	 */
	public connectedCallback(
	): void {
		super.connectedCallback();
	}


	// #=#=#=#=#=# 1.? Changed Attributes #=#=#=#=#=#
	/**
	 * Handles changes to the observed attributes of the element
	 */
	public attributeChangedCallback(
		name:string,
		_:string,
		value:string|null
	):void {
		// #════ Axis to Property Mapping ════#
		const {axis, property} = GridLayout.parseAttribute(name);


		// #════ Assign Property ════#
		this.layout.setProperty(axis, property, value);


		// #════ Request Update ════#
		this.requestUpdate(name, value);
	}


	// #═#═#═#═#═# 1.? After Update #═#═#═#═#═#
	protected afterUpdate(): void {
		// #════ Column Count ════#
		const columnCount	=	this.layout.getProperties('column', false).trackCount;
		if(columnCount) {
			this._styles.setProperty(':host', '--_column-count', columnCount);
			console.log(this._styles);
		}

		else {
			this._styles.removeProperty(':host', '--_column-count');
		}


		// #════ Row Count ════#
		const rowCount	=	this.layout.getProperties('row', false).trackCount;
		if(rowCount) {
			this._styles.setProperty(':host', '--_row-count', rowCount);
		}

		else {
			this._styles.removeProperty(':host', '--_row-count');
		}
	}
}


// ══════════════════════════════════════════════════
// MARK: 2. Initialization
// ──────────────────────────────────────────────────
window.customElements.define('gw-grid', GWGridElement);