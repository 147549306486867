/**
 * Inhaltsverzeichnis
 * 	1. Grid Item Element
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #═#═#═#═#═# 0.1 Imports #═#═#═#═#═#
import BaseElement from '../../base/index.tsx';
import { CSSRuleSet } from '../../../ts/class/style/exportlist.ts';
import OwnLayout from '../assets/own-layout';
import stylesheet from './style.scss?inline';


// #═#═#═#═#═# 0.2 Types #═#═#═#═#═#
import type {
	AxisDirection,
} from '../assets/types.d.ts';


// ══════════════════════════════════════════════════
// MARK: 1. Grid Item Element
// ──────────────────────────────────────────────────
export default class GWGridItemElement extends BaseElement {
	// #═#═#═#═#═# 1.1 Properties #═#═#═#═#═#
		// #════ Static ════#
	static readonly _styles:CSSRuleSet			=	new CSSRuleSet(stylesheet);
	/** List of observed Attributes */
	static readonly axisAttributes				=	[
		'span'
	];
	protected static readonly attributeMap?: Record<string, string> = {
		'span':		'trackSpan'
	};


		// #════ Initialised ════#
	/** The layouting helper class instance that can be passed to the webworker */
	protected readonly layout:OwnLayout			=	new OwnLayout(this.attributeMap);


		// #════ Uninitialized ════#


	// #═#═#═#═#═# 1.2 Constructor #═#═#═#═#═#
	constructor(
		
	) {
		// #════ Parent ════#
		super();


		// #════ Properties ════#


		// #════ Actions ════#


	}


	//
	// MARK: Lifecycle
	//
	// #═#═#═#═#═# 1.? Get Observed Attributes #═#═#═#═#═#
	/**
	 * Create the list of observed attributes
	 */
	static get observedAttributes(): string[] {
		const attributes:string[]	=	[];

		for(const axis of ['column', 'row'] as AxisDirection[]) {
			for(const attribute of this.axisAttributes) {
				attributes.push(`${axis}-${attribute}`);
			}
		}

		return attributes;
	}


	// #=#=#=#=#=# 1.? Changed Attributes #=#=#=#=#=#
	/**
	 * Handles changes to the observed attributes of the element
	 */
	public attributeChangedCallback(
		name:string,
		_:string,
		value:string|null
	):void {
		// #════ Axis to Property Mapping ════#
		const {axis, property} = OwnLayout.parseAttribute(name);


		// #════ Assign Property ════#
		this.layout.setProperty(axis, property, value);


		// #════ Request Update ════#
		this.requestUpdate();
	}
}


// ══════════════════════════════════════════════════
// MARK: MARK: 2. Initialization
// ──────────────────────────────────────────────────
window.customElements.define('gw-grid-item', GWGridItemElement);