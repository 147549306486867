// ==================================================
// MARK: 0. Imports
// --------------------------------------------------
import CSSRuleSet from './ruleset.ts';


// ==================================================
// MARK: 1. Style Manager
// --------------------------------------------------
export default class CSSStyleSheetManager {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #==== Static ====#
	/**	A Map containing all Stylesheets for the Component */
	private readonly collection:Map<string, CSSRuleSet>		=	new Map();


	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	/**
	 * Creates a new StyleManager instance
	 * @access		public
	 * @param		stylesheets		Initial content to register
	 */
	public constructor(
		stylesheets?:Record<string, CSSStyleSheet|CSSRuleSet|string>
	) {
		// #==== Guard ====#
		if(!stylesheets) {
			return;
		}


		// #==== Add Stylesheets ====#
		for(const [slug, content] of Object.entries(stylesheets)) {
			this.set(slug, content);
		}
	}


	// #=#=#=#=#=# 1.? Add Entry #=#=#=#=#=#
	/**
	 * Adds a new Stylesheet to the Collection
	 * @access		public
	 * @param		slug			Slug of the Stylesheet
	 * @param		stylesheet		Stylesheet to add
	 */
	public set(
		slug:string,
		stylesheet:CSSStyleSheet|CSSRuleSet|string
	): void {
		// #==== Type Actions ====#
		// If the Stylesheet is a Ruleset, add it to the Collection
		if(stylesheet instanceof CSSRuleSet) {
			this.collection.set(slug, stylesheet);
			return;
		}

		// Convert it to a Ruleset and add it to the Collection
		else {
			this.collection.set(slug, new CSSRuleSet(stylesheet));
		}
	}


	// #=#=#=#=#=# 1.? Get Entry #=#=#=#=#=#
	/**
	 * Returns a Stylesheet from the Collection
	 * @access		public
	 * @param		slug			Slug of the Stylesheet to return
	 * @returns		Returns the Stylesheet to the assosiated Slug
	 */
	public get(
		slug:string
	): undefined|CSSRuleSet {
		return this.collection.get(slug);
	}


	// #=#=#=#=#=# 1.? Remove Entry #=#=#=#=#=#
	/**
	 * Removes a Stylesheet from the Collection
	 * @access		public
	 * @param		slug			Slug of the Stylesheet to remove
	 */
	public remove(
		slug:string
	): void {
		this.collection.delete(slug);
	}


	// #=#=#=#=#=# 1.? Return List #=#=#=#=#=#
	/**
	 * Returns a List of all Stylesheets in the Collection
	 * @access		public
	 * @returns		List of Stylesheets
	 */
	public list(
	): CSSStyleSheet[] {
		// #==== Local Variables ====#
		const list:CSSStyleSheet[]		=	[];	


		// #==== Iterate Collection ====#
		for(const ruleset of this.collection.values()) {
			list.push(ruleset.getStylesheet());
		}


		// #==== Return List ====#
		return list;
	}
}