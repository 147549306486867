/**
 * Inhaltsverzeichnis
 * 	1. Grid Layout
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #═#═#═#═#═# 0.1 Imports #═#═#═#═#═#
import GridAxis from "../grid-axis";
import BaseLayout from "../base-layout";


// #═#═#═#═#═# 0.2 Types #═#═#═#═#═#
import type {
	AxisDirection,
} from "../types.d.ts";


// ══════════════════════════════════════════════════
// MARK: 1. Grid Layout
// ──────────────────────────────────────────────────
export default class GridLayout extends BaseLayout<GridAxis> {
	// #═#═#═#═#═# 1.1 Properties #═#═#═#═#═#
		// #════ Static ════#


		// #════ Uninitialized ════#


		// #════ Initialized ════#
	/** Both axis of the Grid */
	protected readonly axis: Record<AxisDirection, GridAxis>	=	{
		column: new GridAxis({
			trackCount: 1,
			maxLength: 1200,
			gap: "xs"
		}),
		row: new GridAxis({
			trackCount: 0,
			maxLength: Infinity,
			gap: "xs"
		}),
	};


	// #═#═#═#═#═# 1.2 Constructor #═#═#═#═#═#
	constructor(
		attributeMap?: Record<string, string>
	) {
		// #════ Parent ════#
		super(attributeMap);


		// #════ Properties ════#


		// #════ Actions ════#


	}


	//
	// MARK: CSS
	//
	// #═#═#═#═#═# 1.? Generate CSS #═#═#═#═#═#
	public generateCSS(
		selector: string	= ':host'
	): string {
		return `${this.cssRules(selector)}\n${this.cssMediaQuery(selector)}`;
	}


	// #═#═#═#═#═# 1.? Write Media Rules #═#═#═#═#═#
	/**
	 * Write the CSS Rules for the media query
	 */
	protected cssMediaQuery(
		selector: string	= ':host'
	): string {
		// #════ Parameter ════#
		const maxWidth	=	this.axis['column'].maxLength;

		const declarations:string[]	=	[];
		for(const axis of ['column', 'row'] as AxisDirection[]) {
			const trackCount	=	this.cssTrackCount(axis, false);
			if(trackCount) {
				declarations.push(trackCount);
			}
		}


		const rules = declarations.join('\n\t\t');


		// #════ Return ════#
		// TODO: All above the maxWidth should be the defined columnCount
		return rules? `@media screen and (width > ${maxWidth}px) {\n\t${selector} {\n\t\t${rules}\n\t}\n}` : '';
	}


	// #═#═#═#═#═# 1.? Write Rules #═#═#═#═#═#
	/**
	 * Write the CSS Rules for the grid
	 */
	protected cssRules(
		selector: string	= ':host'
	): string {
		// #════ Declarations ════#
		const declarations:string[]	=	[];
		for(const axis of ['column', 'row'] as AxisDirection[]) {
			declarations.push(this.cssTrackGap(axis));

			const trackCount	=	this.cssTrackCount(axis);
			if(trackCount) {
				declarations.push(trackCount);
			}
		}


		const rules = declarations.join('\n\t');


		// #════ Return ════#
		return rules? `${selector} {\n\t${rules}\n}` : '';
	}


	// #═#═#═#═#═# 1.? Track Count #═#═#═#═#═#
	/**
	 * Write the CSS for the track count
	 */
	public cssTrackCount(
		axis: AxisDirection,
		dynamic: boolean	=	true
	): false|string {
		// #════ Properties ════#
		const {trackWidth, trackCount, isSubgrid}	=	this.getProperties(axis);
		const propertyName	=	`grid-template-${axis}s`;


		// #════ Subgrid ════#
		if(isSubgrid) {
			return `${propertyName}: subgrid;`;
		}


		// #════ Guard ════#
		if(trackWidth === Infinity) {
			if(trackCount > 1) {
				return `${propertyName}: repeat(${trackCount}, 1fr);`;
			}

			return false;
		}


		// #════ Count based ════#
		if(dynamic) {
			return `${propertyName}: repeat(auto-fit, minmax(min(100%, ${trackWidth}px), 1fr));`;
		}

		else {
			return `${propertyName}: repeat(${trackCount}, minmax(${trackWidth}px, 1fr));`;
		}
	}


	// #═#═#═#═#═# 1.? Track Gaps #═#═#═#═#═#
	/**
	 * Write the CSS for the track gaps
	 */
	public cssTrackGap(
		axis: AxisDirection
	): string {
		return `${axis}-gap: ${this.axis[axis].gap};`;	
	}
}


// ══════════════════════════════════════════════════
// MARK: . 
// ──────────────────────────────────────────────────
Object.defineProperty(window, 'GridLayout', {
	value: GridLayout,
	writable: false
});