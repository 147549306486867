/**
 * Inhaltsverzeichnis
 * 	1. Processor
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #═#═#═#═#═# 0.1 Imports #═#═#═#═#═#
import WorkerPool from "./index.ts";
import {
	pause,
	resume
} from "./index";


// #═#═#═#═#═# 0.2 Types #═#═#═#═#═#
import type {
	Task,
	Status,
	Payload,
} from "./types";


// ══════════════════════════════════════════════════
// MARK: 1. Processor
// ──────────────────────────────────────────────────
export default class Processor {
	// #═#═#═#═#═# 1.1 Properties #═#═#═#═#═#
		// #════ Static ════#


		// #════ Uninitialized ════#
	/** The WorkerPool to use */
	private workingpool:WorkerPool;
	/** The Web Worker instance */
	private worker?:Worker;
	/** Current Resolve Method */
	#resolve?: (value: any) => void;
	/** Current Reject Method */
	#reject?: (reason?: any) => void;
	/** Timeout Function */
	private timeout?: number;
	
	
		// #════ Initialized ════#
	/** The current status of the worker */
	#status:Status	=	'inactive';


	// #═#═#═#═#═# 1.2 Constructor #═#═#═#═#═#
	constructor(
		workingpool:WorkerPool
	) {
		// #════ Properties ════#
		this.workingpool	=	workingpool;


		// #════ Actions ════#


	}


	//
	// MARK: Hire & Fire
	//
	// #═#═#═#═#═# 1.? Create Worker #═#═#═#═#═#
	/**
	 * Creates a worker instance
	 */
	private hireWorker(
	): Worker {
		// #════ Create ════#
		const file				=	this.workingpool.file;
		const worker			=	typeof file === 'string'? new Worker(file) : file();


		// #════ Add Events ════#
		worker.onmessage		=	this.workingpool['onReceiveMessage'].bind(this.workingpool, this);
		worker.onerror			=	this.workingpool['onReceiveError'].bind(this.workingpool, this);


		// #════ Update Worker Count ════#
		WorkerPool['activeProcessorCount']++;


		// #════ Return ════#
		return worker;
	}


	// #═#═#═#═#═# 1.? Terminate Worker #═#═#═#═#═#
	/**
	 * Terminates the worker
	 */
	public fireWorker(
	):void {
		// #════ Guard ════#
		if(!this.worker) {
			return;
		}


		// #════ Terminate ════#
		this.worker.terminate();
		this.worker		=	undefined;


		// #════ Update Status ════#
		this.status		=	'inactive';


		// #════ Update Worker Count ════#
		WorkerPool['activeProcessorCount']--;
	}


	//
	// MARK: Task Management
	//
	// #═#═#═#═#═# 1.? Request Worker #═#═#═#═#═#
	/**
	 * Requests a new Worker from the WorkerPool
	 */
	private requestWorker(
	):Worker {
		// #════ Guard ════#
		if(!this.worker) {
			this.worker		=	this.hireWorker();
			this.status		=	'idle';
		}


		// #════ Return ════#
		return this.worker;
	}


	// #═#═#═#═#═# 1.? Assign Next Task in Queue #═#═#═#═#═#
	/**
	 * Assigns the next task in the queue
	 */
	public doNextTask(
	):void {
		// #════ Guard ════#
		const task	=	this.workingpool.getNextTask();

		if(!task) {
			return;
		}


		// #════ Assign Task ════#
		this.doTask(task);
	}


	// #═#═#═#═#═# 1.? Do Task #═#═#═#═#═#
	/**
	 * Assigns a task to the Worker
	 */
	public doTask(
		task: Task
	):void {
		// #════ Worker ════#
		const worker	=	this.requestWorker();


		// #════ Save Methods ════#
		this.resolve	=	task.resolve;
		this.reject		=	task.reject;


		// #════ Post Message ════#
		worker.postMessage(task.payload);
		this.status		=	'running';
	}


	//
	// MARK: Worker Control
	//
	// #═#═#═#═#═# 1.? Pause #═#═#═#═#═#
	/**
	 * Pause all workers
	 */
	public pause(
	):void {
		// #════ Guard ════#
		if(!this.worker) {
			return;
		}


		// #════ Inform Worker ════#
		this.worker.postMessage(pause);


		// #════ Update Status ════#
		this.status		=	'paused';
	}


	// #═#═#═#═#═# 1.? Resume #═#═#═#═#═#
	/**
	 * Resume all workers
	 */
	public resume(
	):void {
		// #════ Guard ════#
		if(!this.worker) {
			return;
		}


		// #════ Inform Worker ════#
		this.worker.postMessage(resume);


		// #════ Update Status ════#
		this.status		=	'running';
	}


	// #═#═#═#═#═# 1.? Stop Workers #═#═#═#═#═#
	/**
	 * Stop all workers
	 */
	public stop(
	): void {
		// #════ Guard ════#
		if(!this.worker) {
			return;
		}


		// #════ Inform Worker ════#
		this.fireWorker();


		// #════ Update Status ════#
		this.status		=	'inactive';
	}


	//
	// MARK: Timeout
	//
	// #═#═#═#═#═# 1.? Start Idle #═#═#═#═#═#
	/**
	 * Start idle timeout
	 */
	private startIdleTimeout(
	):void {
		// #════ Guard ════#
		if(this.timeout != undefined) {
			return;
		}


		// #════ Start ════#
		this.timeout	=	setTimeout(this.stop.bind(this), this.workingpool['timeoutDuration']);
	}


	// #═#═#═#═#═# 1.? Stop Idle #═#═#═#═#═#
	/**
	 * Clear idle timeout
	 */
	private clearIdleTimeout(
	):void {
		// #════ Guard ════#
		if(this.timeout == undefined) {
			return;
		}


		// #════ Clear ════#
		clearTimeout(this.timeout);
		this.timeout	=	undefined;
	}


	//
	// MARK: Status
	//
	// #═#═#═#═#═# 1.? Set Staus #═#═#═#═#═#
	/**
	 * Set status of worker pool
	 */
	private set status(
		newState: Status
	) {
		// #════ Guard ════#
		if(
			( this.workingpool.keepAlive && newState === 'inactive' ) ||
			this.status === newState
		) {
			return;
		}


		// #════ State Action ════#
		switch(newState) {
			case 'running':
				this.clearIdleTimeout();
				break;

			case 'paused':
				// TODO: handle Pause
				break;

			case 'idle':
				if(!this.workingpool.keepAlive)
					this.startIdleTimeout();
				break;

			// inactive state does not need to be handled
		}


		// #════ Update State ════#
		console.log(`Status Change: ${this.status} -> ${newState}`);
		this.#status	=	newState;
	}


	// #═#═#═#═#═# 1.? Get Status #═#═#═#═#═#
	/**
	 * Get status of worker pool
	 */
	public get status(
	): Status {
		return this.#status;
	}


	//
	// MARK: Promise Handling
	//
	// #═#═#═#═#═# 1.? Resolve #═#═#═#═#═#
	/**
	 * Resolve the promise
	 */
	public resolve(
		value: any
	):void {
		// #════ Resolve ════#
		if(this.#resolve) {
			this.#resolve(value);
		}


		// #════ Clear ════#
		this.clear();
	}


	// #═#═#═#═#═# 1.? Reject #═#═#═#═#═#
	/**
	 * Reject the promise
	 */
	public reject(
		reason: any
	):void {
		// #════ Reject ════#
		if(this.#reject) {
			this.#reject(reason);
		}


		// #════ Clear ════#
		this.clear();
	}


	// #═#═#═#═#═# 1.? Clear #═#═#═#═#═#
	/**
	 * Clear the promise
	 */
	private clear(
	): void {
		this.#resolve	=	undefined;
		this.#reject	=	undefined;
	}
}