/**
 * Inhaltsverzeichnis
 * 	1. Subgrid Element
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import HTMLGridElement from '../container/index.tsx';
import { CSSRuleSet } from '../../../ts/class/style/exportlist.ts';
import stylesheet from './style.scss?inline';
import type {
	GridAxis,
	SubgridAxisProperties,
	SettableSubgridAxisProperties,
	CSSLength
} from '../types.d.ts';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#


// ══════════════════════════════════════════════════
// MARK: 1. Subgrid Element
// ──────────────────────────────────────────────────
export default class HTMLSubgridElement extends HTMLGridElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #════ Static ════#
	static readonly _styles:CSSRuleSet			=	new CSSRuleSet(stylesheet);
	/** List of observed Attributes */
	static readonly observedAttributes			=	[
		'column-subgrid',
		'column-count',
		'column-span',
		'column-gap',
		'column-auto-flow',
		'column-track-length',
		'row-subgrid',
		'row-count',
		'row-span',
		'row-gap',
		'row-auto-flow',
		'row-track-length'
	];
	/** Provided default values for both track axiss */
	protected static defaultAxisProperties:Record<'column'|'row', SubgridAxisProperties>	=	{
		column: {
			isDynamic: false,
			gap: 25,
			trackCount: 4,
			trackSize: undefined,
			trackSpan: 1,
			trackPattern: 'auto',
			trackPatternLength: 1,
			subgrid: false,
			maxLength: 1200,
			autoFlow: 'auto',
			__evaluatedCount: 4
		},
		row: {
			isDynamic: false,
			gap: 25,
			trackCount: 0,
			trackSize: undefined,
			trackSpan: 1,
			trackPattern: 'auto',
			trackPatternLength: 1,
			subgrid: false,
			maxLength: Infinity,
			autoFlow: 'auto',
			__evaluatedCount: 0
		}
	}


		// #════ Initialised ════#
	/**	Properties for the tracks within the grid layout */
	protected axisProperties:Record<'column'|'row', SubgridAxisProperties>	=	structuredClone(HTMLSubgridElement.defaultAxisProperties);
	/**	Property setter mapping */
	protected setAxisProperty:Record<SettableSubgridAxisProperties, Function>		=	{
		gap: this.setTrackGap,
		trackCount: this.setTrackCount,
		trackSpan: this.setTrackSpan,
		autoFlow: this.setTrackFlow,
	};


		// #════ Uninitialized ════#


	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor(
		
	) {
		// #════ Parent ════#
		super();


		// #════ Properties ════#


		// #════ Actions ════#
	}


	// #=#=#=#=#=# 1.? Changed Attributes #=#=#=#=#=#
	public attributeChangedCallback(
		name:string,
		_:string,
		value:string
	):void {
		// #════ Set Properties ════#
		const { axis, property }	=	HTMLGridElement.parseAttributeName(name);

		if(value != null) {
			switch(property) {
				// +──── Count ────+
				case 'count':
					this.axisProperties[axis].trackCount	=	this.sanitizeValue(value, axis, parseInt, 'trackCount');
					break;

				// +──── Length ────+
				case 'axisLength':
					this.axisProperties[axis].maxLength		=	this.sanitizeValue(value, axis, parseFloat, 'maxLength');
					break;

				// +──── Gap ────+
				case 'gap':
					this.axisProperties[axis].gap			=	this.sanitizeValue(value, axis, this.getSpacingInPixels, 'gap');
					break;

				// +──── Flow ────+
				case 'autoFlow':
					this.axisProperties[axis].autoFlow		=	value as 'auto'|CSSLength;
					break;

				// +──── Pattern ────+
				case 'trackPattern':
					this.axisProperties[axis].trackPattern			=	value ?? HTMLGridElement.defaultAxisProperties[axis].trackPattern;
					this.axisProperties[axis].trackPatternLength	=	value?.split(/\s+(?![^(]*\))/g).length ?? HTMLGridElement.defaultAxisProperties[axis].trackPatternLength;
					break;

				// +──── Subgrid ────+
				case 'subgrid':
					this.axisProperties[axis].subgrid	=	this.sanitizeValue(value, axis, true, 'subgrid', true);
					break;
			}
		}

		else {
			this.axisProperties[axis][property]	=	HTMLSubgridElement.defaultAxisProperties[axis][property];
		}


		// #════ Unobserve Changes ════#
		// FIX: Seperate Debounce Function from Resize Observer
		// Temporarily unobserve the element to prevent infinite update loops
		HTMLSubgridElement.resizeObserver.unobserve(this);


		// #════ Update Loop ════#
		this.updateTracks()


		// #════ Re-Observe ════#
		HTMLSubgridElement.resizeObserver.observe(this);
	}


	// #═#═#═#═#═# 1.? Sanitize Value #═#═#═#═#═#
	/**
	 * Sanitizes the value of an attribute
	 */
	protected sanitizeValue(
		value: string|null,
		axis: GridAxis,
		sanitizer: CallableFunction|any,
		fallbackName: keyof SubgridAxisProperties,
		booleanProperty: boolean	=	false
	): any {
		// #════ Boolean Attribute ════#
		if(booleanProperty) {
			return value !== null ? value !== 'false' : HTMLSubgridElement.defaultAxisProperties[axis][fallbackName];
		}


		// #════ Fallback ════#
		if(value === null || value === '') {
			return HTMLSubgridElement.defaultAxisProperties[axis][fallbackName];
		}


		// #════ Sanitize ════#
		if(typeof sanitizer === 'function') {
			return sanitizer(value);
		}

		else {
			return value;
		}
	}


	// #=#=#=#=#=# 1.? Attribute Functions #=#=#=#=#=#
	/**
	 * Sets the Count of the track axis
	 */
	protected setTrackSpan(
		axis:string,
		value:string
	):void {
		const parentColumns	=	(this.parentElement as HTMLGridElement).getAxisTrackCount();

		// BUG: Spanning does not return to original Layout when resized from smaller to larger screens
		if(axis === 'column') {
			this._styles.setProperty(':host', `grid-${axis}`, `span ${Math.min(parseInt(value), parentColumns)}`);
		}

		else if(parentColumns > 1) {
			this._styles.setProperty(':host', `grid-${axis}`, `span ${value}`);
		}

		else {
			this._styles.setProperty(':host', `grid-${axis}`, `span 1`);
		}
	}


	/**
	 * Sets the Count of explicit Elements within the track axis
	 */
	protected setTrackCount(
		axis: 'column' | 'row',
		value: string
	): void {
		// #════ Properties ════#
		const track	=	this.axisProperties[axis];


		// #════ Subgrid ════#
		if(track.subgrid) {
			this._styles.setProperty(':host', `grid-template-${axis}s`, `subgrid`);
			return;
		}


		// #════ Remove Property ════#
		if(track.trackCount === 0) {
			this._styles.removeProperty(':host', `grid-template-${axis}s`);
			return;
		}


		// #════ Set Property ════#
		else {
			if(
				!this.isWithinLayoutSize(axis) ||
				track.trackSize === Infinity ||
				track.trackSize === undefined
			) {
				this._styles.setProperty(':host', `grid-template-${axis}s`, `repeat(${track.trackCount}, ${track.trackPattern})`);
			}

			else {
				// Set Track Count only within the bounds of the maximum size to a minmax function
				this._styles.setProperty(':host', `grid-template-${axis}s`, `repeat(auto-fit, minmax(min(${track.trackSize}px, 100%), 1fr))`);
			}
		}
	}
}


// ══════════════════════════════════════════════════
// MARK: 2. Initialization
// ──────────────────────────────────────────────────
window.customElements.define('gw-subgrid', HTMLSubgridElement);