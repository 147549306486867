/**
 * Inhaltsverzeichnis
 * 	1. 
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #═#═#═#═#═# 0.1 Imports #═#═#═#═#═#
import BaseLayout from "../base-layout";
import OwnAxis from "../own-axis";


// #═#═#═#═#═# 0.2 Types #═#═#═#═#═#
import type {
	AxisDirection,
} from "../types.d.ts";


// ══════════════════════════════════════════════════
// MARK: 1. 
// ──────────────────────────────────────────────────
export default class OwnLayout extends BaseLayout<OwnAxis> {
	// #═#═#═#═#═# 1.1 Properties #═#═#═#═#═#
		// #════ Static ════#


		// #════ Uninitialized ════#


		// #════ Initialized ════#
	/** Both axis of the Grid */
	protected readonly axis: Record<AxisDirection, OwnAxis>	=	{
		column: new OwnAxis(),
		row: new OwnAxis(),
	};


	// #═#═#═#═#═# 1.2 Constructor #═#═#═#═#═#
	constructor(
		attributeMap?: Record<string, string>
	) {
		// #════ Parent ════#
		super(attributeMap);


		// #════ Properties ════#


		// #════ Actions ════#


	}


	//
	// MARK: Actions
	//
	// #═#═#═#═#═# 1.? Generate CSS #═#═#═#═#═#
	public generateCSS(
		selector: string	= ':host'
	): string {
		return `${this.cssRules(selector)}\n${this.cssMediaQuery(selector)}`;
	}


	// #═#═#═#═#═# 1.? Write Rules #═#═#═#═#═#
	/**
	 * Write the CSS Rules for the grid
	 */
	protected cssRules(
		selector: string	= ':host'
	): string {
		// #════ Declarations ════#
		const declarations:string[]	=	[];
		for(const axis of ['column', 'row'] as AxisDirection[]) {
			declarations.push();

		}


		const rules = declarations.join('\n\t');


		// #════ Return ════#
		return rules? `${selector} {\n\t${rules}\n}` : '';
	}


	// #═#═#═#═#═# 1.? Write Media Rules #═#═#═#═#═#
	/**
	 * Write the CSS Rules for the media query
	 */
	protected cssMediaQuery(
		selector: string	= ':host'
	): string {
		// #════ Parameter ════#
		const maxWidth	=	480;
		
		
		// #════ Declarations ════#
		const declarations:string[]	=	[];
		for(const axis of ['column', 'row'] as AxisDirection[]) {
			const trackSpan = this.cssTrackSpan(axis);

			if(trackSpan) {
				declarations.push(trackSpan);
			}
		}


		const rules = declarations.join('\n\t\t');


		// #════ Return ════#
		return rules? `@media screen and (width > ${maxWidth}px) {\n\t${selector} {\n\t\t${rules}\n\t}\n}` : '';
	}


	// #═#═#═#═#═# 1.? CSS Track Span #═#═#═#═#═#
	/**
	 * Return the CSS for the track span
	 */
	protected cssTrackSpan(
		axis: AxisDirection
	): string {
		// #════ Declarations ════#
		const span	=	this.axis[axis].trackSpan;
		if(span) {
			return `grid-${axis}: span ${span};`;
		}


		// #════ Return ════#
		return '';
	}
}