/**
 * Inhaltsverzeichnis
 * 	1. Combined Layout
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #═#═#═#═#═# 0.1 Imports #═#═#═#═#═#
import OwnLayout from "../own-layout";
import GridLayout from "../grid-layout";
import GridAxis from "../grid-axis";
import OwnAxis from "../own-axis";


// #═#═#═#═#═# 0.2 Types #═#═#═#═#═#
import type {
	AxisDirection,
	SharedLayoutProperties
} from "../types.d.ts";


// ══════════════════════════════════════════════════
// MARK: 1. Combined Layout
// ──────────────────────────────────────────────────
export default class CombinedLayout implements SharedLayoutProperties {
	// #═#═#═#═#═# 1.1 Properties #═#═#═#═#═#
		// #════ Static ════#


		// #════ Uninitialized ════#
	/** An object to map attribute names to property variables */
	protected attributeMap?: Record<string, string>;


		// #════ Initialized ════#
	/** Own Layout */
	protected readonly own: OwnLayout;
	/** Grid Layout */
	protected readonly grid: GridLayout;


	// #═#═#═#═#═# 1.2 Constructor #═#═#═#═#═#
	constructor(
		attributeMap?: Record<string, string>
	) {
		// #════ Properties ════#
		this.attributeMap	=	attributeMap;
		this.own	=	new OwnLayout(this.attributeMap);
		this.grid	=	new GridLayout(this.attributeMap);


		// #════ Actions ════#


	}


	//
	// MARK: Actions
	//
	// #═#═#═#═#═# 1.? Set Property #═#═#═#═#═#
	public setProperty(
		axis: AxisDirection,
		property: string,
		value: any
	): void {
		// #════ Mapped Attributes ════#
		property	=	this.getMappedProperty(property);


		// #════ Set Value ════#
		for(const layout of [this.own, this.grid]) {
			// +──── Get Prototype ────+
			const proto = Object.getPrototypeOf(layout.getProperties(axis)).constructor;

			// +──── Guard ────+
			if(!proto.publicAccessors || !proto.publicAccessors.includes(property)) {
				continue;
			}

			// +──── Set Property ────+
			layout.setProperty(axis, property, value);
		}
	}


	// #═#═#═#═#═# 1.? Get Properties #═#═#═#═#═#
	getProperties<P extends boolean>(
		axis: AxisDirection,
		own: P	=	false as P
	): P extends true ? OwnAxis : GridAxis {
		//@ts-ignore
		return own ? this.own.getProperties(axis) : this.grid.getProperties(axis);
	}


	// #═#═#═#═#═# 1.? Get Mapped Property #═#═#═#═#═#
	/**
	 * Return the corrosponding property for the given attribute
	 */
	public getMappedProperty(
		property: string
	): string {
		// #════ Property Lookup ════#
		if(this.attributeMap) {
			const mappedProperty	=	this.attributeMap[property];
			if(mappedProperty) {
				return mappedProperty;
			}
		}


		// #════ Fallback ════#
		return property;
	}


	// #═#═#═#═#═# 1.? Generate CSS #═#═#═#═#═#
	public generateCSS(
		selector: string	= ':host'
	): string {
		return `${this.cssRules(selector)}\n${this.cssMediaQuery(selector)}`;
	}


	// #═#═#═#═#═# 1.? Write Media Rules #═#═#═#═#═#
	/**
	 * Write the CSS Rules for the media query
	 */
	protected cssMediaQuery(
		selector: string	= ':host'
	): string {
		// #════ Parameter ════#
		const maxWidth	=	this.getProperties('column', false).maxLength;

		const declarations:string[]	=	[];
		for(const axis of ['column', 'row'] as AxisDirection[]) {
			const trackCount	=	this.cssTrackCount(axis, false);
			if(trackCount) {
				declarations.push(trackCount);
			}
		}


		const rules = declarations.join('\n\t\t');


		// #════ Return ════#
		// TODO: All above the maxWidth should be the defined columnCount
		return rules? `@media screen and (width > ${maxWidth}px) {\n\t${selector} {\n\t\t${rules}\n\t}\n}` : '';
	}


	// #═#═#═#═#═# 1.? Write Rules #═#═#═#═#═#
	/**
	 * Write the CSS Rules for the grid
	 */
	protected cssRules(
		selector: string	= ':host'
	): string {
		// #════ Declarations ════#
		const declarations:string[]	=	[];
		for(const axis of ['column', 'row'] as AxisDirection[]) {
			declarations.push(this.cssTrackGap(axis));

			const trackCount	=	this.cssTrackCount(axis);
			if(trackCount) {
				declarations.push(trackCount);
			}

			const trackSpan = this.cssTrackSpan(axis);
			if(trackSpan) {
				declarations.push(trackSpan);
			}
		}


		const rules = declarations.join('\n\t');


		// #════ Return ════#
		return rules? `${selector} {\n\t${rules}\n}` : '';
	}


	// #═#═#═#═#═# 1.? Track Count #═#═#═#═#═#
	/**
	 * Write the CSS for the track count
	 */
	public cssTrackCount(
		axis: AxisDirection,
		dynamic: boolean	=	true
	): false|string {
		// #════ Properties ════#
		const {trackWidth, trackCount, isSubgrid}	=	this.getProperties(axis, false);
		const propertyName	=	`grid-template-${axis}s`;


		// #════ Subgrid ════#
		if(isSubgrid && dynamic) {
			return `${propertyName}: subgrid;`;
		}


		// #════ Guard ════#
		if(trackWidth === Infinity) {
			if(trackCount > 1) {
				return `${propertyName}: repeat(${trackCount}, 1fr);`;
			}

			return false;
		}


		// #════ Count based ════#
		if(dynamic) {
			if(trackCount === 1) {
				return `${propertyName}: 1fr;`;
			}

			else {
				return `${propertyName}: repeat(auto-fit, minmax(min(100%, ${trackWidth}px), 1fr));`;
			}
		}

		else if (trackCount > 1) {
			return `${propertyName}: repeat(${trackCount}, minmax(min(100%, ${trackWidth}px), 1fr));`;
		}


		// #════ Fallback ════#
		return false;
	}


	// #═#═#═#═#═# 1.? Track Gaps #═#═#═#═#═#
	/**
	 * Write the CSS for the track gaps
	 */
	public cssTrackGap(
		axis: AxisDirection
	): string {
		return `${axis}-gap: ${this.getProperties(axis, false).gap};`;	
	}


	// #═#═#═#═#═# 1.? CSS Track Span #═#═#═#═#═#
	/**
	 * Return the CSS for the track span
	 */
	protected cssTrackSpan(
		axis: AxisDirection
	): string {
		// #════ Declarations ════#
		const span	=	this.getProperties(axis, true).trackSpan;
		if(span) {
			return `grid-${axis}: span ${span};`;
		}


		// #════ Return ════#
		return '';
	}
}