/**
 * Inhaltsverzeichnis
 * 	1. Layout Base
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #═#═#═#═#═# 0.1 Imports #═#═#═#═#═#
import GridAxis from "../grid-axis";
import OwnAxis from "../own-axis";
import { camelCase } from '../../../../ts/module/string.ts';


// #═#═#═#═#═# 0.2 Types #═#═#═#═#═#
import type {
	AxisDirection,
	GridAxisPropertyMapping,
	SharedLayoutProperties
} from "../types.d.ts";


// ══════════════════════════════════════════════════
// MARK: 1. Layout Base
// ──────────────────────────────────────────────────
export default abstract class BaseLayout<T extends GridAxis|OwnAxis> implements SharedLayoutProperties {
	// #═#═#═#═#═# 1.1 Properties #═#═#═#═#═#
		// #════ Static ════#


		// #════ Uninitialized ════#
	/** An object to map attribute names to property variables */
	protected attributeMap?: Record<string, string>;


		// #════ Initialized ════#
	/** Both axis of the Grid */
	protected readonly axis: Record<AxisDirection, T>;


	// #═#═#═#═#═# 1.2 Constructor #═#═#═#═#═#
	constructor(
		attributeMap?: Record<string, string>
	) {
		// #════ Properties ════#
		this.attributeMap	=	attributeMap;


		// #════ Actions ════#


	}


	//
	// MARK: Actions
	//
	// #═#═#═#═#═# 1.? Set Property #═#═#═#═#═#
	public setProperty(
		axis: AxisDirection,
		property: string,
		value: any
	): void {
		// #════ Mapped Attributes ════#
		property	=	this.getMappedProperty(property);
		

		// #════ Set Value ════#
		this.axis[axis][property]	=	value;
	}


	// #═#═#═#═#═# 1.? Get Properties #═#═#═#═#═#
	public getProperties(
		axis: AxisDirection
	): T {
		return this.axis[axis];
	}


	// #═#═#═#═#═# 1.? Get Mapped Property #═#═#═#═#═#
	/**
	 * Return the corrosponding property for the given attribute
	 */
	public getMappedProperty(
		property: string
	): string {
		// #════ Property Lookup ════#
		if(this.attributeMap) {
			const mappedProperty	=	this.attributeMap[property];
			if(mappedProperty) {
				return mappedProperty;
			}
		}


		// #════ Fallback ════#
		return property;
	}


	// #═#═#═#═#═# 1.? Generate CSS #═#═#═#═#═#
	public abstract generateCSS(
		selector: string
	): string;


	//
	// MARK: Attributes
	//
	// #=#=#=#=#=# 1.? Parse Attribute Name #=#=#=#=#=#
	/**
	 * Parses the attribute Name into readable parts
	 * 
	 * String of property will be camelized
	 */
	public static parseAttribute(
		name: string
	): GridAxisPropertyMapping {
		// #════ Determin Axis ════#
		const [axis, property]	=	name.split(/-(.+)/);


		// #════ Return ════#
		return {
			axis: axis as AxisDirection,
			property: camelCase(property)
		};
	}
}