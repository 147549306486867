// ==================================================
// MARK: 1. Case Functions
// --------------------------------------------------
// #=#=#=#=#=# 1.1 Camel Case #=#=#=#=#=#
/**
 * Convert a string to camel case
 */
export function camelCase(
	string: string
): string {
    // Using replace method with regEx
    return string.replace(
		/(?:^\w|[A-Z]|\b\w)/g,
		(word, index) => (index == 0 ? word.toLowerCase() : word.toUpperCase())
	).replace(/[\s-]+/g, '');
}