// ==================================================
// MARK: 0. Imports
// --------------------------------------------------


// ==================================================
// MARK: 1. Style Manager
// --------------------------------------------------
export default class CSSRuleSet {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #==== Initialised ====#
	/** Stylesheet */
	private stylesheet:CSSStyleSheet					=	new CSSStyleSheet();


	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	/**
	 * Creates a new StyleManager instance
	 * @access		public
	 * @param		styles		Initial content to register
	 */
	public constructor(
		styles?:string|CSSStyleSheet
	) {
		// #==== Guard ====#
		if(!styles) {
			return;
		}


		// #==== Add Styles ====#
		this.replaceRules(styles);
	}


	// #=#=#=#=#=# 1.? Add Selector #=#=#=#=#=#
	/**
	 * Adds a new Selector to the Ruleset and returns its Index
	 * @access		protected
	 * @param		selector		Selector to add
	 * @returns		Index of the new or existing Rule
	 */
	protected addSelector(
		selector:string
	): number {
		// #==== Guard ====#
		// Dont add a Selector that already exists
		const index = this.getRuleIndex(selector);
		if(index !== -1) {
			return index;
		}


		// #==== Add Rule ====#
		// Inser new Rule at the end of the Stylesheet
		return this.stylesheet.insertRule(`${selector} {}`, this.stylesheet.cssRules.length);
	}


	// #=#=#=#=#=# 1.? Get Rule Declarations #=#=#=#=#=#
	/**
	 * Returns the Declarations of a Rule by Selector
	 * @access		public
	 * @param		index		The index of the Rule
	 */
	protected getDeclarations(
		index:number
	): false|CSSStyleDeclaration {
		// #==== Guard  ====#
		if(index === -1) {
			return false;
		}


		// #==== Rule Item ====#
		const rule = this.stylesheet.cssRules.item(index) as CSSStyleRule;
		return rule.style;
	}


	// #=#=#=#=#=# 1.? Get Rule Index #=#=#=#=#=#
	/**
	 * Returns the Index of a Rule by Selector
	 * @access		protected
	 * @param		selector		Selector to search for
	 */
	protected getRuleIndex(
		selector:string
	): number {
		// #==== Search for Index ====#
		// Construct an array from the CSSRulesList to use findIndex
		const stylesAry		=	Array.from(this.stylesheet.cssRules) as CSSStyleRule[];

		// Find the Index of the Rule
		return stylesAry.findIndex((item):boolean => {
			// Check if Selector matches
			return item.selectorText === selector;
		});
	}


	// #=#=#=#=#=# 1.? Set Property #=#=#=#=#=#
	/**
	 * Adds a new Selector with optional initial delcarations to the Ruleset
	 * @access		public
	 * @param		selector		Selector to add
	 * @param		declarations	Declarations to add
	 */
	public setProperty(
		selector:string,
		key:string,
		value:string|number
	): void {
		// #==== Add new Selector ====#
		// Add a new Selector if it does not exist
		const index				=	this.addSelector(selector);
		// Get its declarations
		const declarations		=	this.getDeclarations(index);


		// #==== Guard ====#
		// Bail if the declarations object is not found
		if(!declarations) {
			return;
		}


		// #==== Set Property ====#
		// Declare the Property
		declarations.setProperty(key, value.toString());
	}


	// #=#=#=#=#=# 1.? Delete Proptery #=#=#=#=#=#
	/**
	 * Deletes a Property from a Rule
	 * @access		public
	 * @param		selector		Selector to delete
	 * @param		key				Key to delete
	 */
	public removeProperty(
		selector:string,
		key:string
	): void {
		// #==== Search for Index ====#
		const index = this.getRuleIndex(selector);
		const declarations = this.getDeclarations(index);


		// #==== Guard ====#
		if(!declarations) {
			return;
		}


		// #==== Delete Property ====#
		declarations.removeProperty(key);


		// #==== Clear empty Entry ====#
		// Check if the Rule is empty and delete it
		if(declarations.length === 0) {
			this.deleteRule(index);
		}
	}


	// #=#=#=#=#=# 1.? Replace Rules #=#=#=#=#=#
	/**
	 * Replaces the Ruleset with new content
	 * @access		public
	 * @param		styles		New content to replace
	 */
	public replaceRules(
		styles:string|CSSStyleSheet
	): void {
		// #==== Replace Rules ====#
		switch(typeof styles) {
			// Replace File Content
			case 'string':
				this.stylesheet.replaceSync(styles);
				break;

			// Replace entire File
			case 'object':
				this.stylesheet		=	styles;
				break;
		}
	}


	// #=#=#=#=#=# 1.? Delete Rule #=#=#=#=#=#
	/**
	 * Deletes a Rule from the Ruleset
	 * @access		public
	 * @param		selector		Selector to delete
	 */
	public deleteRule(
		selector:string|number
	): void {
		// #==== Search for Index ====#
		let index:number;
		switch(typeof selector) {
			case 'string':
				index  = this.getRuleIndex(selector);
				break;

			case 'number':
				index = selector;
				break;
		}


		// #==== Guard ====#
		if(index === -1) {
			return;
		}


		// #==== Delete Rule ====#
		this.stylesheet.deleteRule(index);
	}


	// #=#=#=#=#=# 1.? Clear Rules #=#=#=#=#=#
	/**
	 * Clears all Rules from the Ruleset
	 * @access		public
	 */
	public clearRules(
	): void {
		// #==== Clear Rules ====#
		this.replaceRules('');
	}


	// #=#=#=#=#=# 1.? Get Stylesheet #=#=#=#=#=#
	/**	
	 * Returns the Stylesheet of the Ruleset
	 * @access		public
	 */
	public getStylesheet(
	): CSSStyleSheet {
		return this.stylesheet;
	}
}