/**
 * Inhaltsverzeichnis
 * 	1. 
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #=#=#=#=#=# 0.1 Imports #=#=#=#=#=#
import WorkerFile from '../worker.ts?worker';
import WorkerPool from '../../../ts/class/workerpool/index.ts';
import BaseElement from '../../base/index.tsx';
import { CSSRuleSet, CSSStyleSheetManager } from '../../../ts/class/style/exportlist.ts';
import { debounce } from '../../../ts/module/timing.ts';
import { camelCase } from '../../../ts/module/string.ts';
import stylesheet from './style.scss?inline';


// #=#=#=#=#=# 0.2 Types #=#=#=#=#=#
import type {
	CSSUnit,
	GridAxis,
	GridAxisProperties,
	SettableAxisProperties,
	CSSLength,
	WorkerRequest
} from '../types.d.ts';


/**
 * ### Specs
 * 
 * - Axis: An axis contains multiple explicit and implicit tracks.
 * - Track: describes a single line in the grid layout within an axis.  
 * 	- It can contain a pattern of subdivisions.
 */
// ══════════════════════════════════════════════════
// MARK: 1. HTML Grid Element
// ──────────────────────────────────────────────────
// REFACTOR: rewrite auto-fit to caltulated integer
export default class HTMLGridElement extends BaseElement {
	// #=#=#=#=#=# 1.1 Properties #=#=#=#=#=#
		// #════ Static ════#
	/** Webworkers */
	static readonly workers:WorkerPool				=	new WorkerPool(WorkerFile, 5);
	/** The Stylesheet for the gloabl Element */
	static readonly _styles:CSSRuleSet				=	new CSSRuleSet(stylesheet);
	/** List of observed Attributes */
	static readonly observedAttributes				=	[
		'column-count',
		'column-gap',
		'column-auto-flow',
		'column-track-pattern',
		'column-axis-length',
		'row-count',
		'row-gap',
		'row-auto-flow',
		'row-track-pattern',
		'row-axis-length',
	];
	/** Provided default values for both track axis */
	protected static readonly defaultAxisProperties:Record<GridAxis, GridAxisProperties>	=	{
		column: {
			isDynamic: false,
			gap: 25,
			trackCount: 4,
			trackSize: undefined,
			trackPattern: '1fr',
			trackPatternLength: 1,
			maxLength: 1200,
			autoFlow: 'auto',
			__evaluatedCount: 4
		},
		row: {
			isDynamic: false,
			gap: 25,
			trackCount: 0,
			trackSize: undefined,
			trackPattern: 'auto',
			trackPatternLength: 1,
			maxLength: Infinity,
			autoFlow: 'auto',
			__evaluatedCount: 0
		}
	}
	/**	The Resize Observer */
	protected static readonly resizeObserver:ResizeObserver		=	new ResizeObserver(debounce((entries:ResizeObserverEntry[]) => {
		entries.forEach((entry) => {
			(entry.target as HTMLGridElement).updateTracks();
		});
	}, 100) as ResizeObserverCallback);


		// #════ Initialised ════#
	/** An Interface for all contained styles localy */
	readonly _styles:CSSRuleSet							=	new CSSRuleSet();
	/**	Object containing the Ruleset for the Host Element */
	protected readonly _ruleset:CSSStyleSheetManager	=	new CSSStyleSheetManager({
		static: HTMLGridElement._styles,
		element: this._styles
	});
	/**	Properties for the tracks within the grid layout */
	protected axisProperties:Record<GridAxis, GridAxisProperties>		=	structuredClone(HTMLGridElement.defaultAxisProperties);
	/**	Property setter mapping */
	protected readonly setAxisProperty:Record<SettableAxisProperties, Function>		=	{
		trackCount: this.setTrackCount,
		gap: this.setTrackGap,
		autoFlow: this.setTrackFlow,
	};
	/**	Debounced Update Function */
	protected readonly updateTracks:Function		=	debounce(this.__unstableUpdateTracks.bind(this), 100);


		// #════ Uninitialized ════#


	// #=#=#=#=#=# 1.2 Constructor #=#=#=#=#=#
	constructor(
		
	) {
		// #════ Parent ════#
		super();


		// #════ Properties ════#
		this.shadowRoot.adoptedStyleSheets		=	this._ruleset.list();


		// #════ Actions ════#
		HTMLGridElement.workers.keepAlive = true;


		// #════ Observe Resize ════#
		HTMLGridElement.resizeObserver.observe(this);
	}


	// #═#═#═#═#═# 1.? Webworker Change #═#═#═#═#═#
	private webworkerChange(
	): void {	
		// #════ Add Task ════#
		const task:WorkerRequest	=	{
			type: "grid",
			properties: this.axisProperties
		};
		const response	=	HTMLGridElement.workers.addTask(task);

		response.then((answer) => {
			console.log(answer);
		});
	}


	// #=#=#=#=#=# 1.? Changed Attributes #=#=#=#=#=#
	/**
	 * Handles changes to the observed attributes of the element
	 */
	public attributeChangedCallback(
		name:string,
		_:string,
		value:string|null
	):void {
		// #════ Set Properties ════#
		const { axis, property }	=	HTMLGridElement.parseAttributeName(name);

		switch(property) {
			// +──── Count ────+
			case 'count':
				this.axisProperties[axis].trackCount	=	this.sanitizeValue(value, axis, parseInt, 'trackCount');
				break;

			// +──── Length ────+
			case 'axisLength':
				this.axisProperties[axis].maxLength		=	this.sanitizeValue(value, axis, parseFloat, 'maxLength');
				break;

			// +──── Spacing ────+
			case 'gap':
				this.axisProperties[axis].gap			=	this.sanitizeValue(value, axis, this.getSpacingInPixels, 'gap');
				break;

			// +──── Flow ────+
			case 'autoFlow':
				this.axisProperties[axis].autoFlow		=	value as 'auto'|CSSLength;
				break;

			// +──── Pattern ────+
			case 'trackPattern':
				this.axisProperties[axis].trackPattern			=	value ?? HTMLGridElement.defaultAxisProperties[axis].trackPattern;
				this.axisProperties[axis].trackPatternLength	=	value?.split(/\s+(?![^(]*\))/g).length ?? HTMLGridElement.defaultAxisProperties[axis].trackPatternLength;
				break;
		}


		// #════ Unobserve Changes ════#
		// FIX: Seperate Debounce Function from Resize Observer
		// Temporarily unobserve the element to prevent infinite update loops
		HTMLGridElement.resizeObserver.unobserve(this);


		// #════ Update Loop ════#
		this.updateTracks()


		// #════ Re-Observe ════#
		HTMLGridElement.resizeObserver.observe(this);
	}


	// #═#═#═#═#═# 1.? Sanitize Value #═#═#═#═#═#
	/**
	 * Sanitizes the value of an attribute
	 */
	protected sanitizeValue(
		value: string|null,
		axis: GridAxis,
		sanitizer: CallableFunction|any,
		fallbackName: keyof GridAxisProperties,
		booleanProperty: boolean	=	false
	): any {
		// #════ Boolean Attribute ════#
		if(booleanProperty) {
			return value !== null ? value !== 'false' : HTMLGridElement.defaultAxisProperties[axis][fallbackName];
		}


		// #════ Fallback ════#
		if(value === null || value === '') {
			return HTMLGridElement.defaultAxisProperties[axis][fallbackName];
		}


		// #════ Sanitize ════#
		if(typeof sanitizer === 'function') {
			return sanitizer(value);
		}

		else {
			return value;
		}
	}


	// #=#=#=#=#=# 1.? Track Update Loop #=#=#=#=#=#
	/**
	 * Update the Tracks of the Grid
	 */
	protected __unstableUpdateTracks(
	): void {
		this.webworkerChange();


		// #════ Evaluate Track Size ════#
		for(const axis of ['column', 'row'] as GridAxis[]) {
			const track	=	this.axisProperties[axis];
	

			// +──── Guard ────+
			if(track.maxLength === Infinity) {
				this.axisProperties[axis].trackSize			=	HTMLGridElement.defaultAxisProperties[axis].trackSize;
				this.axisProperties[axis].__evaluatedCount	=	track.trackCount;
				continue;
			}


			// +──── Calculate Single Track Size ────+
			this.axisProperties[axis].trackSize			=	(track.maxLength - (track.gap * (track.trackCount - 1))) / track.trackCount;
			this.axisProperties[axis].__evaluatedCount	=	this.isWithinLayoutSize(axis) ? Math.floor(this.clientWidth / (track.trackSize as number)) : track.trackCount;
		}


		// #════ Debug ════#
		console.groupCollapsed('Grid Attributes', this);
		console.table(this.axisProperties);
		console.groupEnd();


		// #════ Update Tracks ════#
		for(let [axis, track] of Object.entries(this.axisProperties)) {
			axis	=	axis as GridAxis;

			for(const [property, value] of Object.entries(track)) {
				const callback	=	this.setAxisProperty[property as keyof GridAxisProperties];

				if(callback) {
					callback.call(this, axis, value.toString());
				}
			}
		}
	}


	// #=#=#=#=#=# 1.? Get Column Count #=#=#=#=#=#
	/**
	 * Returns the current Column Count
	 */
	public getAxisTrackCount(
		axis: GridAxis			=	'column',
		subdivisions: boolean	=	false
	):number {
		// #════ Properties ════#
		const track =	this.axisProperties[axis];
		let count: number;


		// #════ Dynamic Layout ════#
		if(track.isDynamic) {
			const style	=	window.getComputedStyle(this);
			const cumputedTrack	=	axis === 'column'? style.gridTemplateColumns : style.gridTemplateRows;
			count	=	cumputedTrack.split(' ').length;

			if(!subdivisions) {
				count	=	count / track.trackPatternLength;
			}
		}


		// #════ Static Layout ════#
		else{
			count	=	track.__evaluatedCount;

			if(subdivisions) {
				count	=	count * track.trackPatternLength;
			}
		}


		return count;
	}


	// #=#=#=#=#=# 1.? Set Track Count #=#=#=#=#=#
	/**
	 * Sets the Count of explicit Elements within the track axis
	 */
	protected setTrackCount(
		axis: GridAxis,
		_: string
	): void {
		// #════ Properties ════#
		const track		=	this.axisProperties[axis];


		// #════ Remove Property ════#
		if(track.trackCount === 0) {
			this._styles.removeProperty(':host', `grid-template-${axis}s`);
			return;
		}


		// #════ Set Property ════#
		let layout	=	'';
		if(track.__evaluatedCount <= 1) {
			layout	=	track.trackPattern;
		}

		else {
			layout	=	`repeat(${track.__evaluatedCount}, ${track.trackPattern})`;
		}

		this._styles.setProperty(':host', `grid-template-${axis}s`, layout);
	}


	// #=#=#=#=#=# 1.? Set Track Gap #=#=#=#=#=#
	/**
	 * Sets the Spacing between each track in an axis
	 */
	protected setTrackGap(
		axis: GridAxis,
		// value: string
	): void {
		// #════ Value ════#
		let value	=	this.getAttribute(`${axis}-gap`) ?? 's';
		

		// #════ Convert Value Type ════#
		if(value.match(/^(?:xs|s|m|l|xl|xxl)$/mi)) {
			value	=	`var(--cvh-space-${value})`;
		}

		else if(value.startsWith('--')) {
			value	=	`var(${value})`;
		}


		// #════ Pass to Element CSS ════#
		this._styles.setProperty(':host', `${axis}-gap`, value);
	}


	// #=#=#=#=#=# 1.? Set Auto Flow #=#=#=#=#=#
	/**
	 * Sets the auto flow property of the track axis
	 */
	protected setTrackFlow(
		axis: GridAxis,
		value: string
	): void {
		// #════ Pass to Element CSS ════#
		if(value === 'auto') {
			this._styles.removeProperty(':host', `grid-auto-${axis}s`);
		}

		else {
			this._styles.setProperty(':host', `grid-auto-${axis}s`, `minmax(${value}, auto)`);
		}
	}


	// #=#=#=#=#=# 1.? Get Spacing Value #=#=#=#=#=#
	/**
	 * Converts a size string into its pixel value
	 */
	protected getSpacingInPixels(
		value: string
	): number {
		// #════ Variable Spacings ════#
		switch(value) {
			case 'xxs': 
				return 5;
			case 'xs': 
				return 15;
			case 's':
				return 25;
			case 'm':
				return 35;
			case 'l':
				return 50;
			case 'xl':
				return 75;
			case 'xxl':
				return 115;
		}


		// #════ Unit Spacings ════#
		const match:{
			lenght: string,
			unit: CSSUnit
		}|undefined		=	value.match(/(?<lenght>\d+)(?<unit>px|rem|vw|vh|dvw|dvh|svw|svh|lvw|lvh|vmin|vmax|%)/)?.groups as {lenght: string,unit: CSSUnit}|undefined;

			// +──── Guard ────+
			// No Match
		if (!match) {
			return 0;
		}

			// No Unit = Assume Pixels
		else if(!match.unit) {
			return parseInt(match.lenght);
		}


		// #════ Convert to Pixels ════#
		const length	=	parseInt(match.lenght);
		switch(match.unit) {
			// +──── Pixel ────+
			case 'px':
				return length;
			// +──── Root Font Size ────+
			case 'rem':
				return length * 10;
			// +──── Viewport Width ────+
			case 'vw':
			case 'dvw':
			case 'svw':
			case 'lvw':
				return window.innerWidth * (length / 100);
			// +──── Viewport Height ────+
			case 'vh':
			case 'dvh':
			case 'svh':
			case 'lvh':
				return window.innerHeight * (length / 100);
			// +──── Viewport Min/Max ────+
			case 'vmin':
				return Math.min(window.innerWidth, window.innerHeight) * (length / 100);
			case 'vmax':
				return Math.max(window.innerWidth, window.innerHeight) * (length / 100);
			// +──── Parent Width ────+
			case '%':
				return (this.parentElement?.clientWidth ?? 1440) * (length / 100);
		}


		// #════ Default ════#
		return 0;
	}


	// #=#=#=#=#=# 1.? Is within Layout Size #=#=#=#=#=#
	/**
	 * Checks if the current element size is within the boundries of the max layout size
	 */
	protected isWithinLayoutSize(
		axis: string
	): boolean {
		const track		=	this.axisProperties[axis as GridAxis];
		const size		=	this.getBoundingClientRect()[`width`];

		if(track.maxLength === Infinity) {
			return true
		}

		if(isNaN(track.maxLength)) {
			return false;
		}

		return size <= track.maxLength;
	}


	// #=#=#=#=#=# 1.? Parse Attribute Name #=#=#=#=#=#
	/**
	 * Parses the attribute Name into readable parts
	 * 
	 * String of property will be camelized
	 */
	protected static parseAttributeName(
		name: string
	): TrackAxisDeclaration {
		const portions = name.split('-');
		const axis = portions.shift();
		return {
			axis: axis as GridAxis,
			property: camelCase(portions.join('-'))
		};
	}
}


// ══════════════════════════════════════════════════
// MARK: 2. Initialization
// ──────────────────────────────────────────────────
window.customElements.define('gw-grid', HTMLGridElement);