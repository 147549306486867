/**
 * Inhaltsverzeichnis
 * 	1. Worker pool
 * 		1.1 Properties
 * 		1.2 Constructor
 * 		1.3 
 */
// ══════════════════════════════════════════════════
// MARK: 0. Setup
// ──────────────────────────────────────────────────
// #═#═#═#═#═# 0.1 Imports #═#═#═#═#═#
import Processor from './processor.ts';


// #═#═#═#═#═# 0.2 Types #═#═#═#═#═#
import type {
	Payload,
	Task,
	Status
} from './types';


// #═#═#═#═#═# 0.3 Symbols #═#═#═#═#═#
export const pause	=	Symbol('pause');
export const resume	=	Symbol('resume');

type WorkerReturning = () => Worker;


// ══════════════════════════════════════════════════
// MARK: 1. Worker pool
// ──────────────────────────────────────────────────
export default class WorkerPool {
	// #═#═#═#═#═# 1.1 Properties #═#═#═#═#═#
		// #════ Static ════#
	/** List of active WorkerPools */
	private static activePools:Set<WorkerPool>		=	new Set();
	/** Count of all active workers */
	private static activeProcessorCount:number		=	0;
	/** Number of overall available workers */
	private static availableProcessorCount:number	=	20; 


		// #════ Uninitialized ════#
	/**	The web worker file */
	#file:string|WorkerReturning;
	/** Maximum amount of workers available */
	private maxProcessors: number;
	/** Timeout function to terminate all workers */
	private timeout?: number;


		// #════ Initialized ════#
	/** Current Status of workers */
	#status: Status							=	'inactive';
	/** List of all created workers */
	private processors:Set<Processor>		=	new Set();
	/** List of all available workers */
	private availableProcessors:Processor[]	=	[];
	/** Task Queue */
	private taskQueue:Task[]				=	[];
	/** timeoutDuration */
	private timeoutDuration:number			=	30000;
	/** Will keep the threads alive */
	public keepAlive:boolean				=	false;


	// #═#═#═#═#═# 1.2 Constructor #═#═#═#═#═#
	constructor(
		file: string,
		workerCount: number,
		timeoutDuration?: number
	) {
		// #════ Properties ════#
		this.#file			=	file;
		this.maxProcessors	=	workerCount;
		if(timeoutDuration	!=	undefined) {
			this.timeoutDuration	=	timeoutDuration;
		}
		
		
		// #════ Actions ════#
		WorkerPool.storeInstance(this);
		this.hireWorkforce();


		// #════ Set Status ════#
		this.status		=	'idle';
	}


	//
	// MARK: Instances
	//
	// #═#═#═#═#═# 1.? Store Instance #═#═#═#═#═#
	/**
	 * Pass instance to active pools
	 * @param pool 
	 */
	private static storeInstance(
		pool: WorkerPool
	):void {
		WorkerPool.activePools.add(pool);
	}


	// #═#═#═#═#═# 1.? Terminate Instance #═#═#═#═#═#
	/**
	 * Delete instance from active pools
	 */
	public terminate():void {
		// Stop and delete all workers
		this.stop();

		// Delete instance from active pools
		WorkerPool.activePools.delete(this);
	}


	//
	// MARK: Methods
	//
	// #═#═#═#═#═# 1.? Hire Workers #═#═#═#═#═#
	/**
	 * Creates new Processor instances
	 */
	private hireWorkforce(
	):void {
		// #════ Create Processor ════#
		for(let i = 0; i < this.maxProcessors; i++) {
			// New class instance
			const processor	=	new Processor(this);

			// Add processor to list
			this.processors.add(processor);
			this.availableProcessors.push(processor);
		}
	}


	//
	// MARK: Worker Actions
	//
	// #═#═#═#═#═# 1.? Worker Messages #═#═#═#═#═#
	/**
	 * Fires after receiving a message from a worker
	 */
	private onReceiveMessage(
		processor:	Processor,
		event:	MessageEvent<Payload>
	):void {
		console.log('Recieved Message:', event.data);


		// #════ Resolve Task ════#
		// Mark task as resolved
		processor.resolve(event.data);


		// #════ Actions ════#
		this.orderProcessor(processor);
	}


	// #═#═#═#═#═# 1.? Worker Error #═#═#═#═#═#
	/**
	 * Fires after receiving an error from a worker
	 */
	private onReceiveError(
		processor:	Processor,
		event:	MessageEvent<Payload>
	):void {
		// #════ Reject Task ════#
		// Mark task as rejected
		processor.reject(event.data);


		// #════ Actions ════#
		this.orderProcessor(processor);
	}


	// #═#═#═#═#═# 1.? Order Processor #═#═#═#═#═#
	/**
	 * Give next task to worker or push worker back to available workers
	 */
	public orderProcessor(
		processor: Processor
	):void {
		// #════ Assign next Task ════#
		if(this.taskQueue.length > 0) {
			processor.doNextTask();
		}


		// #════ Mark as available ════#
		else {
			this.availableProcessors.push(processor);
		}
	}



	//
	// MARK: Task Handling
	//
	// #═#═#═#═#═# 1.? Add Task #═#═#═#═#═#
	/**
	 * Run a new Task
	 */
	public addTask(
		payload: Payload
	):Promise<Payload> {
		// #════ Promise ════#
		return new Promise((resolve, reject) => {
			// +──── Task ────+
			const task:Task	=	{
				payload,
				resolve,
				reject
			};

			// +──── Assign ────+
			const processor	=	this.getProcessor();

			if(processor) {
				processor.doTask(task);
			}

			else {
				this.taskQueue.push(task);
			}
		});
	}


	// #═#═#═#═#═# 1.? Get Processor #═#═#═#═#═#
	/**
	 * Get next available Processor
	 */
	private getProcessor(
	):Processor|undefined {
		return this.availableProcessors.shift();	
	}


	// #═#═#═#═#═# 1.? Get Task #═#═#═#═#═#
	/**
	 * Returns the next Task in the TaskQueue
	 */
	public getNextTask(
	):Task|undefined {
		return this.taskQueue.shift();
	}


	//
	// MARK: Timeout
	//
	// #═#═#═#═#═# 1.? Start Idle #═#═#═#═#═#
	/**
	 * Start idle timeout
	 */
	private startIdleTimeout(
	):void {
		// #════ Guard ════#
		if(this.timeout != undefined) {
			return;
		}


		// #════ Start ════#
		this.timeout	=	setTimeout(this.stop.bind(this), this.timeoutDuration);
	}


	// #═#═#═#═#═# 1.? Stop Idle #═#═#═#═#═#
	/**
	 * Clear idle timeout
	 */
	private clearIdleTimeout(
	):void {
		// #════ Guard ════#
		if(this.timeout == undefined) {
			return;
		}


		// #════ Clear ════#
		clearTimeout(this.timeout);
		this.timeout	=	undefined;
	}


	//
	// MARK: Worker Control
	//
	// #═#═#═#═#═# 1.? Pause #═#═#═#═#═#
	/**
	 * Pause all workers
	 */
	public pause(
	):void {
		this.status		=	'paused';

		// TODO: Implement pause

	}


	// #═#═#═#═#═# 1.? Resume #═#═#═#═#═#
	/**
	 * Resume all workers
	 */
	public resume(
	):void {
		// Set status to idle
		this.status		=	'running';
	}
	// TODO: Implement resume


	// #═#═#═#═#═# 1.? Stop Workers #═#═#═#═#═#
	/**
	 * Stop all workers
	 */
	public stop(
	): void {
		// TODO: Implement stop
	}


	// #═#═#═#═#═# 1.? Terminate all workers #═#═#═#═#═#
	/**
	 * Terminate all workers
	 */
	public terminateWorkforce(
	):void {
		// #════ Guard ════#
		if(this.keepAlive) {
			return;
		}


		// #════ Terminate Workers ════#
		this.processors.forEach(processor => {
			processor.fireWorker();
		});
	}


	//
	// MARK: Setters & Getters
	//
	// #═#═#═#═#═# 1.? Set Staus #═#═#═#═#═#
	/**
	 * Set status of worker pool
	 */
	private set status(
		newState: Status
	) {
		// #════ Guard ════#
		if(
			( this.keepAlive && newState === 'inactive' ) ||
			this.status === newState
		) {
			return;
		}


		// #════ State Action ════#
		switch(newState) {
			case 'running':
				this.clearIdleTimeout();
				break;

			case 'paused':
				// TODO: handle Pause
				break;

			case 'idle':
				if(!this.keepAlive)
					this.startIdleTimeout();
				break;

			// inactive state does not need to be handled
		}


		// #════ Update State ════#
		console.log(`Status Change: ${this.status} -> ${newState}`);
		this.#status	=	newState;
	}


	// #═#═#═#═#═# 1.? Get Status #═#═#═#═#═#
	/**
	 * Get status of worker pool
	 */
	public get status(
	): Status {
		return this.#status;
	}


	// #═#═#═#═#═# 1.? Get File #═#═#═#═#═#
	/**
	 * getter function to prevent unwanted overwriting
	 */
	public get file(
	): string|WorkerReturning {
		return this.#file;
	}
}